<template>
    <div :class="{ 'blobs-bg relative': !intro_title }">
        <Header
            v-if="intro_title"
            :title="intro_title"
            :text="article.intro_text_nl"
            :image="cdnurl(article.intro_image)"
            title_class="size-3"
            type="small"
            :showUSPS="$route.name !== 'news_single'"
        >
            <template v-slot:pretitle v-if="$route.name === 'news_single'">
                <div class="size-6 color-secondary py-s leading-1 flex align-center">
                    {{ timestamp_to_date(article.created.timestamp) }}
                </div>
            </template>
        </Header>

        <Layout :class="{ 'mt-xxl': !intro_title }">
            <template v-slot:content>
                <div
                    class="color-secondary leading-m l-up:pr-xl accentlink richtext"
                    v-if="typeof article.config_nl === 'string'"
                    v-html="article.config_nl"
                />
            </template>
            <template v-slot:sidebar>
                <Sidebar type="article" />
            </template>
        </Layout>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Layout from '@/components/Layout-Sidebar.vue';
import Sidebar from '@/components/Sidebar.vue';
import { replaceBrackets } from '@/assets/helpers/string';

export default {
    name: 'Article',
    components: {
        Header,
        Sidebar,
        Layout,
    },
    data() {
        return {
            article: {},
        };
    },
    beforeRouteEnter(to, from, next) {
        next((vm) => vm.getData());
    },
    beforeRouteUpdate(to, from, next) {
        next();
        this.getData();
    },
    methods: {
        getData() {
            if (window.PRERENDER_INJECTED) {
                const routedata = window.PRERENDER_INJECTED.routeData[this.$route.path];
                if (routedata) {
                    this.processData(routedata);
                }
            } else if (this.$route.name === 'Services') {
                if (this.$store.getters.data && this.$store.getters.data.services) {
                    const article = this.$store.getters.data.services.find((it) => it.id === +this.$route.params.id);
                    this.processData(article);
                } else {
                    setTimeout(this.getData, 100);
                }
            } else {
                const url = replaceBrackets(this.$route.meta.article.endpoint, this.$route.params);
                this.$store.dispatch('get', url).then(this.processData);
            }
        },

        processData(r) {
            this.article = r;

            let schemas = [];
            if (this.$route.meta.article.schema) {
                if (typeof this.$route.meta.article.schema === 'function') {
                    schemas = this.$route.meta.article.schema(r, this);
                } else {
                    schemas = this.$route.meta.article.schema;
                }
            }
            this.$store.commit('setMeta', {
                data: {
                    title: this.intro_title || r.meta_title_nl,
                    titleDefault: `${process.env.VUE_APP_NAME}`,
                    description: (r.intro_text_nl || r.meta_description_nl).substr(0, 100),
                },
                schemas,
            });
        },

        timestamp_to_date(val) {
            if (typeof val === 'number') {
                const timestamp = val * 1000;
                const date = new Date(timestamp);
                return new Intl.DateTimeFormat('nl-NL', { day: '2-digit', month: '2-digit' }).format(date);
            }
            return val;
        },
    },
    computed: {
        intro_title() {
            return this.$route.name !== 'news_single' ? this.article.intro_title_nl : this.article.name;
        },
    },
};
</script>
